<template>
  <button
    v-if="mode === 'mobile'"
    class="button-feedback-mobile"
    @click="onClick"
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M6.16909 17.5C3.64427 16.0479 2 13.6776 2 11C2 6.58172 6.47715 3 12 3C17.5228 3 22 6.58172 22 11C22 14.8703 18.5645 18.2588 14 19"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 11V11.01"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 11V11.01"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 11V11.01"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0007 19C12.7647 19 11.4019 19.5 10.1589 20.145C8.16092 21.182 7.16192 21.701 6.66992 21.37C6.17792 21.04 6.41518 18.932 6.45792 17.966C6.50065 17 6.50065 16 6.50065 16"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    ОСТАВИТЬ ЗАЯВКУ
  </button>
  <button v-else class="btn-primary button-feedback" @click="onClick">
    ОСТАВИТЬ ЗАЯВКУ
  </button>
</template>

<script>
import { mapMutations } from "vuex";
import { gtmSubmitApplication } from "@/utils/gtm";

export default {
  name: "ButtonFeedback",
  props: {
    mode: String,
  },
  methods: {
    ...mapMutations(["showFeedbackModal"]),
    onClick() {
      this.showFeedbackModal();
      gtmSubmitApplication();
    },
  },
};
</script>

<style>
.button-feedback-mobile {
  justify-content: center;
  width: 100%;
}
</style>
