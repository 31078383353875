export const PANORAMA = {
  options: {
    name: "interior-x-cross-7",
    items: [
      {
        src: require("@/assets/img/cars/x-cross-7/interior/light-q75.webp"),
        srcMobile: require("@/assets/img/cars/x-cross-7/interior/mobile/light-q25.webp"),
        startLookAt: 270,
      },
      {
        src: require("@/assets/img/cars/x-cross-7/interior/dark-q75.webp"),
        srcMobile: require("@/assets/img/cars/x-cross-7/interior/mobile/dark-q25.webp"),
        startLookAt: 270,
      },
    ],
  },
  infospots: [
    {
      position: {
        x: 2300,
        y: -1800,
        z: 4100,
      },
      title: "Атмосферная многоцветная подсветка",
      desc: "Выполненная в виде изящных светодиодных линий, придает интерьеру современный и технологичный вид.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/11.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-doors",
    },
    {
      position: {
        x: -800,
        y: 400,
        z: 5100,
      },
      title: "Боковые подушки и шторки безопасности",
      desc: "Эффективно защищают пассажиров при боковом ударе.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/44.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-doors",
    },
    {
      position: {
        x: -800,
        y: 400,
        z: -5100,
      },
      title: "Боковые подушки и шторки безопасности",
      desc: "Эффективно защищают пассажиров при боковом ударе.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/44.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-doors",
    },
    {
      position: {
        x: 4800,
        y: -1950,
        z: 2000,
      },
      title: "Контролировать системы автомобиля удобно",
      desc: "С помощью 7-дюймовой цифровой панели приборов.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/19.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-digitalcluster",
    },
    {
      position: {
        x: 4850,
        y: -1250,
        z: 1500,
      },
      title: "Мягкое покрытие панели с экокожей",
      desc: "Обладает приятным тактильным эффектом.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/9.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-cluster",
    },
    {
      position: {
        x: 4600,
        y: -2500,
        z: 950,
      },
      title: "Бесключевой доступ и дистанционный запуск с ключа",
      desc: "Позволяет всегда быть готовым к движению.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/15.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-key",
    },
    {
      position: {
        x: 3600,
        y: -3700,
        z: -200,
      },
      title: "Беспроводная зарядка для смартфона",
      desc: "Автомобиль помогает всегда оставаться на связи.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/34.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-key",
    },
    {
      position: {
        x: 3200,
        y: -3700,
        z: 600,
      },
      title: "Электрический стояночный тормоз",
      desc: "Электрический стояночный тормоз с функцией Autohold.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/40.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-key",
    },
    {
      position: {
        x: 4600,
        y: -3400,
        z: -200,
      },
      title: "Двухзонный климат-контроль",
      desc: "Учитывает индивидуальные потребности водителя и пассажиров.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/35.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-key",
    },
    {
      position: {
        x: 4800,
        y: -1600,
        z: 3200,
      },
      title: "Обогрев рулевого колеса",
      desc: "Позволяет комфортно управлять автомобилем в холодную погоду с первых секунд после запуска.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/7.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-key",
    },
    {
      position: {
        x: 3500,
        y: -2500,
        z: 2400,
      },
      title: "Регулируемая рулевая колонка по вылету и высоте",
      desc: "Обеспечивает удобство управления для водителей разного роста и комплекции.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/36.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-key",
    },
    {
      position: {
        x: 4850,
        y: -1500,
        z: -190,
      },
      title: "Мультимедиа с 10-дюймовым экраном ",
      desc: "Обладает полным функционалом смартфона благодаря функциям Carplay и Android Auto.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/18.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-multimedia",
    },
    {
      position: {
        x: 5200,
        y: 900,
        z: 50,
      },
      title: "Датчики дождя и света",
      desc: "Оперативно и автоматически включают фары и стеклоочистители.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/37.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-multimedia",
    },
    {
      position: {
        x: 5850,
        y: -650,
        z: -1500,
      },
      title: "Обогрев ветрового стекла и обогреваемые форсунки",
      desc: "Обеспечивают отличную видимость при любой погоде.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/38.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-multimedia",
    },
    {
      position: {
        x: -600,
        y: -4100,
        z: 3100,
      },
      title: "Кожаные эргономичные сиденья",
      desc: "Обеспечивают удобство в дальних поездках.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/30.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-seat",
    },
    {
      position: {
        x: 1500,
        y: -4300,
        z: 3000,
      },
      title: "Сиденье водителя с электрорегулировкой в 6 направлениях",
      desc: "Позволяет идеально настроить рабочее место за рулем.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/20.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-usb",
    },
    {
      position: {
        x: 1500,
        y: -4300,
        z: 0,
      },
      title: "Передний центральный подлокотник",
      desc: "Оснащен охлаждаемым боксом.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/25.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-capacity",
    },
    {
      position: {
        x: -4500,
        y: -2300,
        z: 0,
      },
      title: "Центральный подлокотник",
      desc: "Для комфортной посадки на втором ряду.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/41.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-capacity",
    },
    {
      position: {
        x: -900,
        y: -4300,
        z: 0,
      },
      title: "Дефлекторы обдува для второго ряда",
      desc: "Обеспечивают климатический комфорт.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/42.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-capacity",
    },
    {
      position: {
        x: -4400,
        y: 1800,
        z: -4200,
      },
      title: "Освещение второго ряда сидений",
      desc: "Обеспечивает дополнительный комфорт для пассажиров.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/22.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-seatcolors",
    },
    {
      position: {
        x: -4200,
        y: -2750,
        z: -1600,
      },
      title: "Обогрев сидений первого и второго ряда",
      desc: "Актуальны большую часть года, и дарят особую радость от встречи с автомобилем.",
      media: { picture: require("@/assets/img/cars/x-cross-7/media/23.webp") },
      gtmReachGoal: "x-cross-7_interior_pin-seatcolors",
    },
    {
      position: {
        x: 3000,
        y: 2950,
        z: 0,
      },
      title: "Панорамная крыша c электроприводом и защитной шторкой",
      desc: "Дарит новые ощущения от поездок",
      media: {
        picture: require("@/assets/img/cars/x-cross-7/media/10.webp"),
      },
      gtmReachGoal: "x-cross-7-exterior-pin-black",
    },
  ],
};

export const GALLERY = {
  folder: "x-cross-7/exterior",
  colorActive: 0,
  colors: [
    {
      title: "Парадиз",
      hex: "#004CB4",
      folder: "blue",
      fileName: {
        light: "XCblue_day00",
      },
    },
    {
      title: "Иней",
      hex: "#FEFAF4",
      folder: "white",
      fileName: {
        light: "XCwhite_day00",
      },
    },
    {
      title: "Лунное затмение",
      hex: "#161313",
      folder: "black",
      fileName: {
        light: "XCblack_day00",
      },
    },
    {
      title: "Роса",
      hex: "#BABABA",
      folder: "silver",
      fileName: {
        light: "XCsilver_day00",
      },
    },
    {
      title: "Магнат",
      hex: "#444A4E",
      folder: "steel",
      fileName: {
        light: "XCsteel_day00",
      },
    },
    {
      title: "Овация",
      hex: "#BE1500",
      folder: "red",
      fileName: {
        light: "XCred_day00",
      },
    },
  ],
  breakpoints: [
    {
      slideIndex: 0,
      items: [
        {
          offset: {
            top: "1vw",
            left: "-12vw",
          },
          offsetMobile: {
            top: "0",
            left: "-11",
          },
          title: "Светодиодные фары",
          desc: "Обеспечивают отличную обзорность и стабильно работают при любой погоде.",
          media: {
            picture: require("@/assets/img/cars/x-cross-7/media/1.webp"),
          },
          gtmReachGoal: "x-cross-7-exterior-pin-led",
        },
        {
          offset: {
            top: "-7.5vw",
            left: "-2.5vw",
          },
          offsetMobile: {
            top: "-7",
            left: "-3",
          },
          title: "Панорамная крыша c электроприводом и защитной шторкой",
          desc: "Дарит новые ощущения от поездок",
          media: {
            picture: require("@/assets/img/cars/x-cross-7/media/10.webp"),
          },
          gtmReachGoal: "x-cross-7-exterior-pin-black",
        },
        {
          offset: {
            top: "-4.5vw",
            left: "6.5vw",
          },
          offsetMobile: {
            top: "-4.5",
            left: "3.5",
          },
          title: "Наружные зеркала заднего вида",
          desc: "Оснащены электрорегулировкой, указателями поворота, электроприводом складывания и обогревом.",
          media: {
            picture: require("@/assets/img/cars/x-cross-7/media/39.webp"),
          },
          gtmReachGoal: "x-cross-7-exterior-pin-black",
        },
        {
          offset: {
            top: "0vw",
            left: "-6vw",
          },
          offsetMobile: {
            top: "-1.5",
            left: "-6",
          },
          title: "Передние динамические указатели поворота",
          desc: "Улучшают дизайн и повышают безопасность маневров.",
          media: {
            picture: require("@/assets/img/cars/x-cross-7/media/8.webp"),
          },
          gtmReachGoal: "x-cross-7-exterior-pin-led",
        },
      ],
    },
    {
      slideIndex: 50,
      items: [
        {
          offset: {
            top: "6.5vw",
            left: "9.5vw",
          },
          offsetMobile: {
            top: "4",
            left: "5",
          },
          title: "Дорожный просвет 190мм",
          desc: "Позволяет уверенно продвигаться по заснеженным и плохим дорогам.",
          media: {
            picture: require("@/assets/img/cars/x-cross-7/media/33.webp"),
          },
          gtmReachGoal: "x-cross-7-exterior-pin-black",
        },
        {
          offset: {
            top: "4.5vw",
            left: "-13vw",
          },
          offsetMobile: {
            top: "2",
            left: "-12",
          },
          title: "18-дюймовые диски колес",
          desc: "Улучшают внешний вид и ходовые качества.",
          media: {
            picture: require("@/assets/img/cars/x-cross-7/media/5.webp"),
          },
          gtmReachGoal: "x-cross-7-exterior-pin-black",
        },
      ],
    },
    {
      slideIndex: 170,
      items: [
        {
          offset: {
            top: "-2vw",
            left: "-16vw",
          },
          offsetMobile: {
            top: "-3",
            left: "-13",
          },
          title: "Светодиодная задняя оптика и монофонарь",
          desc: "Повышают безопасность движения и формируют яркий стиль кроссовера.",
          media: {
            picture: require("@/assets/img/cars/x-cross-7/media/32.webp"),
          },
          gtmReachGoal: "x-cross-7-exterior-pin-number",
        },
        {
          offset: {
            top: "-6vw",
            left: "-13vw",
          },
          offsetMobile: {
            top: "-7",
            left: "-10",
          },
          title: "Багажник вместительностью от 375 до 1400 л",
          desc: "Поможет перевезти самые разные грузы.",
          media: {
            picture: require("@/assets/img/cars/x-cross-7/media/31.webp"),
          },
          gtmReachGoal: "x-cross-7-exterior-pin-luggagecarrier",
        },
        {
          offset: {
            top: "2vw",
            left: "-8vw",
          },
          offsetMobile: {
            top: "1",
            left: "-8",
          },
          title: "Электропривод двери багажника",
          desc: "Присутствует во всех комплектациях и оснащен функцией автоматического открывания без помощи рук.",
          media: {
            picture: require("@/assets/img/cars/x-cross-7/media/43.webp"),
          },
          gtmReachGoal: "x-cross-7-exterior-pin-luggagecarrier",
        },
      ],
    },
  ],
};
