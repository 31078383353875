<template>
  <div class="gallery" :class="{ show: show }">
    <div class="slider">
      <button
        class="slider-button slider-button-prev"
        :disabled="locked"
        @mouseup="prev"
      ></button>
      <button
        class="slider-button slider-button-next"
        :disabled="locked"
        @mouseup="next"
      ></button>
      <button
        v-if="isMobile"
        class="slider-button slider-button-mobile"
        :disabled="locked"
        v-touch:swipe.right="prev"
        v-touch:swipe.left="next"
      ></button>
      <transition name="fade-pins">
        <div class="slider-pins">
          <slot
            :currentBreakpointIndex="currentBreakpointIndex"
            :hide-all-popover="hideAllPopover"
          />
        </div>
      </transition>
      <div class="slider-body">
        <div
          class="slider-slide"
          v-for="(item, index) in end / step"
          :key="index"
        >
          <img
            class="slider-slide__pic"
            :class="{ active: index * step === currentSlideIndex }"
            :src="getImagePath(index * step)"
            alt=""
          />
        </div>
      </div>
    </div>
    <svg
      class="gallery__button"
      width="180"
      height="40"
      viewBox="0 0 180 40"
      fill="none"
      :class="[$route.name]"
    >
      <circle
        @click="prev"
        cx="20"
        cy="20"
        r="19.5"
        fill="#4F5862"
        fill-opacity="0.3"
        stroke="white"
      />
      <circle
        @click="next"
        cx="160"
        cy="20"
        r="19.5"
        fill="#4F5862"
        fill-opacity="0.3"
        stroke="white"
      />
      <path
        pointer-events="none"
        d="M166.5 19.5L156.367 13.9629L156.638 25.5068L166.5 19.5ZM13.5 19.5L23.3649 25.5015L23.6299 13.9575L13.5 19.5ZM157.476 18.7117C123.791 19.5001 121.443 19.5 90.5 19.5V21.5C121.457 21.5 123.821 21.4999 157.523 20.7112L157.476 18.7117ZM90.5 19.5C59.4921 19.5 57.1993 19.5001 22.5156 18.7067L22.4698 20.7062C57.1692 21.4999 59.4773 21.5 90.5 21.5V19.5Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
import {
  GALLERY_STEP_DEGREE,
  GALLERY_TRANSITION,
  GALLERY_TICK,
  GALLERY_BEGIN,
  GALLERY_END,
} from "@/data/constants";
import { getGalleryImage } from "@/utils/helpers";

import { mapState } from "vuex";

export default {
  name: "Gallery3D",
  props: {
    show: {
      type: Boolean,
    },
    begin: {
      type: Number,
      default: GALLERY_BEGIN,
    },
    end: {
      type: Number,
      default: GALLERY_END,
    },
    step: {
      type: Number,
      default: GALLERY_STEP_DEGREE,
    },
    folder: {
      type: String,
    },
    color: {
      type: String,
    },
    file: {
      type: String,
    },
    breakpoints: {
      type: Array,
    },
    initalBreakpoint: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(["isMobile", "theme"]),
  },
  data() {
    return {
      transitionDuration: GALLERY_TRANSITION,
      transitionTick: GALLERY_TICK,
      currentSlideIndex: null,
      currentBreakpointIndex: null,
      nextBreakpointIndex: null,
      locked: false,
      direction: null,
      hideAllPopover: false,
    };
  },
  mounted() {
    setTimeout(() => window.dispatchEvent(new Event("resize")), 500);
  },
  created() {
    this.init();
  },
  unmounted() {
    this.destroy();
  },
  methods: {
    init() {
      this.currentSlideIndex =
        this.breakpoints[this.initalBreakpoint].slideIndex;
      this.currentBreakpointIndex = this.initalBreakpoint;

      window.addEventListener("keydown", this.onKeyPress);
    },
    destroy() {
      window.removeEventListener("keydown", this.onKeyPress);
    },
    getImagePath(index) {
      const isHigh = this.breakpoints.some((e) => e.slideIndex === index);
      const device = this.isMobile ? "mobile" : "desktop";
      const quality = isHigh ? "high" : "low";
      const { folder, color, file } = this;
      if (this.theme === "light")
        return getGalleryImage(folder, device, color, quality, file, index);
    },
    beforeStartAnimation() {
      return new Promise((resolve) => {
        this.hideAllPopover = true;
        setTimeout(() => {
          resolve();
        }, this.transitionDuration);
      });
    },
    afterEndAnimation() {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.hideAllPopover = false;
          resolve();
        }, this.transitionDuration);
      });
    },
    getNextBreakpointIndex() {
      const maxLength = this.breakpoints.length - 1;

      if (this.direction === "next") {
        if (this.currentBreakpointIndex < maxLength) {
          this.currentBreakpointIndex++;
        } else {
          this.currentBreakpointIndex = 0;
        }
      } else {
        if (this.currentBreakpointIndex > 0) {
          this.currentBreakpointIndex--;
        } else {
          this.currentBreakpointIndex = maxLength;
        }
      }

      return this.breakpoints[this.currentBreakpointIndex].slideIndex;
    },
    async tick() {
      if (this.direction === "next") {
        if (this.currentSlideIndex >= this.end - this.step) {
          this.currentSlideIndex = this.begin;
        } else {
          this.currentSlideIndex = this.currentSlideIndex + this.step;
        }
      } else {
        if (this.currentSlideIndex <= this.begin) {
          this.currentSlideIndex = this.end - this.step;
        } else {
          this.currentSlideIndex = this.currentSlideIndex - this.step;
        }
      }

      if (this.currentSlideIndex === this.nextBreakpointIndex) {
        await this.afterEndAnimation();
        this.locked = false;
      } else {
        setTimeout(() => {
          window.requestAnimationFrame(this.tick);
        }, this.transitionTick);
      }
    },
    async animation() {
      this.locked = true;
      await this.beforeStartAnimation();
      this.nextBreakpointIndex = this.getNextBreakpointIndex();
      setTimeout(() => {
        window.requestAnimationFrame(this.tick);
      }, this.transitionTick);
    },
    prev() {
      if (this.locked) return false;

      this.direction = "prev";
      this.animation();
    },
    next() {
      if (this.locked) return false;

      this.direction = "next";
      this.animation();
    },
    onKeyPress(e) {
      if (this.locked) return;

      if (e.key == "ArrowRight") {
        this.next();
      } else if (e.key == "ArrowLeft") {
        this.prev();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.gallery {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  // transform: scale(1.05);
  &.show {
    // transform: scale(1);
    transition: all 0.2s 0.2s;
    opacity: 1;
    visibility: visible;
  }
  &__button {
    width: uniW(180);
    height: uniW(40);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: uniW(-90);
    margin-top: uniW(216);
    &.x-cross-7 {
      margin-top: uniW(248);
    }
    @include mobile {
      top: auto;
      bottom: uniWM(175);
      width: uniWM(180);
      height: uniWM(40);
      margin-left: uniWM(-90);
      margin-top: 0;
    }
  }
}

.slider {
  &-slide {
    &__pic {
      position: absolute;
      top: 0;
      left: -15%;
      width: 130%;
      height: 100%;
      object-fit: cover;
      visibility: hidden;
      @include mobile {
        left: -12%;
        width: 120%;
        height: 100%;
      }
      &.active {
        visibility: visible;
      }
    }
  }
  &-button {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    &-prev {
      left: 0;
    }
    &-next {
      left: 50%;
    }
    &-mobile {
      left: 0;
      width: 100%;
      z-index: 11;
    }
  }
}

svg {
  z-index: 1000;
}

circle {
  cursor: pointer;
}

.fade-pins-enter-active,
.fade-pins-leave-active {
  transition: opacity 0.2s linear;
}

.fade-pins-enter-from,
.fade-pins-leave-to {
  opacity: 0;
}
</style>
