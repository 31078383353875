import {
  PAGE_NAME_CINEMA,
  PAGE_NAME_VESTA_SE,
  PAGE_NAME_VESTA_SW,
} from "@/data/constants";
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CinemaView from "../views/CinemaView.vue";
import VestaSEView from "../views/VestaSEView.vue";
import XCross7View from "../views/XCross7View.vue";
import {
  gtmXCross7CrossExterior,
  gtmXCross7CrossInterior,
  gtmVestaSedanExterior,
  gtmVestaSedanInterior,
} from "@/utils/gtm";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/cinema",
    name: PAGE_NAME_CINEMA,
    component: CinemaView,
  },
  {
    path: "/vesta-sedan",
    name: PAGE_NAME_VESTA_SE,
    component: VestaSEView,
  },
  {
    path: "/x-cross-7",
    name: PAGE_NAME_VESTA_SW,
    component: XCross7View,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// if (process.env.NODE_ENV === "development" || store.state.isMobile) {
// if (process.env.NODE_ENV === "development") {
/**
 * Глобальная переменная window.pageName используется в связке с библиотекой panolens.js
 * для приостановки неактивных в данный момент панорам
 *
 * Процесс инициализации ресурсоемкий(в частности на мобильных устройствах),
 * поэтому панорамы специально не рушатся и не реинициализируются
 */
router.afterEach((to, from) => {
  const { name, query } = to;
  const { mode } = query;

  if (name === "home") {
    window.pageName = "main";
  } else if (mode === "interior") {
    window.pageName = "interior-" + name;
  } else {
    window.pageName = null;
  }

  onChangeCarMode(to, from);
});
// }

function onChangeCarMode(to, from) {
  if (to.name === from.name) {
    const { mode } = to.query;

    gtmEvents(to.name, mode);
  }
}

function gtmEvents(name, mode) {
  if (name === PAGE_NAME_VESTA_SE) {
    if (mode === "interior") {
      gtmVestaSedanInterior();
    } else if (mode === "exterior") {
      gtmVestaSedanExterior();
    }
  } else if (name === PAGE_NAME_VESTA_SW) {
    if (mode === "interior") {
      gtmXCross7CrossInterior();
    } else if (mode === "exterior") {
      gtmXCross7CrossExterior();
    }
  }
}

export default router;
