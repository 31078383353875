<template>
  <transition name="fade-modal">
    <div v-if="tutorialModalShow" class="modal">
      <div class="modal-bg" @click="onClickHide"></div>
      <div class="modal-close" @click="onClickHide">
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
          <path
            d="m17 16.9997-5.6569-5.6568m0 11.3137L17 16.9997l-5.6569 5.6569ZM17 16.9997l5.6569-5.6568L17 16.9997Zm0 0 5.6569 5.6569L17 16.9997Z"
            stroke="rgba(246, 167, 35, 1)"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="modal-content">
        <TutorialSwiper />
        <div class="modal-foot">
          <button class="btn-info" @click="onClickSkip">Пропустить</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import TutorialSwiper from "@/components/TutorialSwiper.vue";
import { gtmNavigationClose, gtmNavigationSkip } from "@/utils/gtm";

export default {
  name: "TutorialModalComponent",
  components: {
    TutorialSwiper,
  },
  created() {
    document.addEventListener("keyup", this.keyPressEscape);
  },
  unmounted() {
    document.removeEventListener("keyup", this.keyPressEscape);
  },
  computed: {
    ...mapState(["tutorialModalShow"]),
  },
  methods: {
    ...mapMutations(["hideTutorialModal"]),
    keyPressEscape(e) {
      if (e.key == "Escape") {
        this.onClickHide();
      }
    },
    onClickHide() {
      this.hideTutorialModal();
      gtmNavigationClose();
    },
    onClickSkip() {
      this.hideTutorialModal();
      gtmNavigationSkip();
    },
  },
};
</script>
