<template>
  <transition name="fade-loading">
    <div v-if="loading" class="loading">
      <video
        ref="video"
        class="loading__video"
        src="@/assets/video/preloader.mp4"
        autoplay
        muted
        loop
        playsinline
      ></video>
      <div class="loading__foot">
        <div class="loading__bar">
          <span :style="{ width: `${currentTick * 10}%` }"></span>
        </div>
        <div class="loading__text" :data-index="currentTick">
          <div class="loading__number">
            <ul>
              <li><span></span><span>0</span><span>0</span></li>
              <li><span></span><span>1</span><span>2</span></li>
              <li><span></span><span>2</span><span>1</span></li>
              <li><span></span><span>3</span><span>4</span></li>
              <li><span></span><span>4</span><span>6</span></li>
              <li><span></span><span>5</span><span>2</span></li>
              <li><span></span><span>6</span><span>5</span></li>
              <li><span></span><span>7</span><span>6</span></li>
              <li><span></span><span>8</span><span>4</span></li>
              <li><span></span><span>9</span><span>2</span></li>
              <li><span>1</span><span>0</span><span>0</span></li>
            </ul>
          </div>
          <span>%</span>
        </div>
      </div>
      <div style="display: none">
        <div v-if="init">
          <picture v-for="(item, index) in filesPart" :key="index">
            <img :src="item" alt="" @load="onLoad" @error="onError" />
          </picture>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { FILES, FILES_MOBILE } from "@/data/preload";
import { gtmScreenLoading } from "@/utils/gtm";

export default {
  name: "LoadingComponent",
  computed: {
    ...mapState(["loading", "isMobile"]),
    tickTransition() {
      return this.transitionTick + "ms";
    },
    calcStep() {
      return Math.floor((this.filesLoaded * 10) / this.files.length);
    },
    filesPart() {
      return [...this.files].splice(0, this.partSize);
    },
  },
  watch: {
    filesLoaded(val) {
      if (val >= this.partSize) {
        this.partSize = this.partSize + 100;
      }
    },
  },
  data() {
    return {
      init: false,
      currentTick: 0,
      amountTick: 10,
      transitionTick: 350,
      delayFallback: process.env.NODE_ENV === "development" ? 0 : 60 * 1000, // секунд
      filesLoaded: 0,
      files: [],
      activeMusic: false,
      partSize: 100,
    };
  },
  created() {
    this.getFiles();
    this.animate();
    this.fallback();
  },
  mounted() {
    this.onLoadVideo();
  },
  methods: {
    ...mapMutations(["setLoading", "showTutorialModal"]),
    getFiles() {
      this.files = this.isMobile ? FILES_MOBILE : FILES;
    },
    fallback() {
      setTimeout(() => {
        if (this.loading) {
          this.hide();
        }
      }, this.delayFallback);
    },
    onLoadVideo() {
      this.$refs.video.addEventListener(
        "canplaythrough",
        () => {
          this.init = true;
        },
        false
      );
    },
    onLoad() {
      // console.log("success load");
      this.increment();
    },
    onError() {
      // console.log("error load");
      this.increment();
    },
    hide() {
      this.setLoading(false);
      if (process.env.NODE_ENV !== "development") {
        this.showTutorial();
      }
      window.dispatchEvent(new Event("resize"));
      gtmScreenLoading();
    },
    showTutorial() {
      setTimeout(() => {
        if (this.$route.name === "home") {
          this.showTutorialModal();
        }
      }, 5000);
    },
    increment() {
      this.filesLoaded++;
    },
    animate() {
      const interval = setInterval(() => {
        if (this.currentTick >= this.amountTick) {
          this.hide();
          clearInterval(interval);
          return;
        }

        if (this.currentTick < this.amountTick) {
          if (this.currentTick < this.calcStep) {
            this.currentTick = this.currentTick + 1;
          }
        }
      }, this.transitionTick);
    },
    onToggleSound(state) {
      this.$refs.video.muted = state;
    },
  },
};
</script>

<style scoped lang="scss">
$heightBar: 72;

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--colorBlack);
  z-index: 350;
  display: grid;
  align-items: center;
  justify-content: center;
  z-index: 5000;
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 35%;
    @include mobile {
      object-fit: contain;
      object-position: 50%;
    }
  }
  &__foot {
    text-align: center;
    position: absolute;
    bottom: uniH(80);
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: uniW(60);
    @include mobile {
      flex-direction: column-reverse;
      align-items: center;
      bottom: uniHM(40);
    }
  }
  &__text {
    --transition: 0.4s;
    color: var(--colorGreyLight);
    display: flex;
    justify-content: center;
    &[data-index="0"] {
      ul {
        transform: translateY(uniW(-$heightBar * 0));
        @include mobile {
          transform: translateY(uniWM(-$heightBar * 0));
        }
        li:nth-child(1) {
          span:nth-child(3) {
            transform: translateY(0);
            transition: 0s var(--transition);
          }
        }
      }
    }
    @for $i from 1 through 9 {
      &[data-index="#{$i}"] {
        ul {
          transform: translateY(uniW(-$heightBar * $i));
          @include mobile {
            transform: translateY(uniWM(-$heightBar * $i));
          }
          li:nth-child(#{$i}) {
            span:nth-child(3) {
              transform: translateY(uniW(-$heightBar));
              transition: 0s var(--transition);
              @include mobile {
                transform: translateY(uniWM(-$heightBar));
              }
            }
          }
          li:nth-child(#{$i + 1}) {
            span:nth-child(3) {
              transform: translateY(0);
            }
          }
        }
      }
    }
    &[data-index="10"] {
      ul {
        transform: translateY(uniW(-$heightBar * 10));
        @include mobile {
          transform: translateY(uniWM(-$heightBar * 10));
        }
        li:nth-child(10) {
          span:nth-child(3) {
            transform: translateY(0);
            transition: none;
          }
        }
        li:nth-child(11) {
          span:nth-child(3) {
            transform: translateY(0);
            transition: none;
          }
        }
      }
    }
    ul {
      @include reset-list;
      position: absolute;
      top: 0;
      right: 0;
      transition: var(--transition);
      li {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        height: uniW($heightBar);
        line-height: uniW($heightBar);
        @include mobile {
          height: uniWM($heightBar);
          line-height: uniWM($heightBar);
        }
        span {
          font-size: uniW(72);
          @include mobile {
            font-size: uniWM(48);
          }
          &:nth-child(3) {
            transform: translateY(uniW(20));
            transition: var(--transition) calc(var(--transition) / 2);
          }
        }
      }
    }
    > span {
      position: relative;
      top: uniW(4);
      font-size: uniW(24);
      line-height: uniW($heightBar);
      @include mobile {
        top: uniWM(4);
        font-size: uniWM(24);
        line-height: uniWM($heightBar);
      }
    }
  }
  &__number {
    position: relative;
    height: uniW($heightBar);
    width: uniW(120);
    overflow: hidden;
    margin-left: uniW(-55);
    @include mobile {
      height: uniWM($heightBar);
      width: uniWM(120);
      margin-left: uniWM(-55);
    }
  }
  &__bar {
    width: 80%;
    height: uniW(4);
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: uniW(100);
    overflow: hidden;
    @include mobile {
      width: uniWM(300);
      height: uniWM(4);
      border-radius: uniWM(100);
      margin-top: uniWM(10);
    }
    span {
      display: block;
      height: 100%;
      background-color: var(--colorGreyLight);
      transition-timing-function: linear;
      transition-duration: v-bind(tickTransition);
    }
  }
}

.fade-loading-leave {
  &-active {
    transition: opacity 0.5s 1s;
    .loading__video {
      transition: opacity 0.5s 0.5s;
    }
    .loading__foot {
      transition: opacity 0.5s;
    }
  }
  &-to {
    opacity: 0;
    .loading {
      &__video {
        opacity: 0;
      }
      &__foot {
        opacity: 0;
      }
    }
  }
}
</style>
