import { getAllGalleryImages } from "@/utils/helpers";
// import { GALLERY as galleryVestaSE } from "@/data/vesta-se";
import { GALLERY as galleryXCross7 } from "@/data/x-cross-7";
import { RESOURCES as cinema } from "@/data/cinema";
import { PANORAMA_LIST as hallPanoramaList } from "@/data/hall";
import { TUTORIAL_LIST } from "@/data/tutorial";

/**
 * Медифайлы для пинов vesta sedan
 */
// const mediaVestaSE = [
//   require("@/assets/img/cars/vesta-se/media/1.webp"),
//   require("@/assets/img/cars/vesta-se/media/2.webp"),
//   require("@/assets/img/cars/vesta-se/media/3.webp"),
//   require("@/assets/img/cars/vesta-se/media/4.webp"),
//   require("@/assets/img/cars/vesta-se/media/5.webp"),
//   require("@/assets/img/cars/vesta-se/media/6.webp"),
//   require("@/assets/img/cars/vesta-se/media/7.webp"),
//   require("@/assets/img/cars/vesta-se/media/8.webp"),
//   require("@/assets/img/cars/vesta-se/media/10.webp"),
//   require("@/assets/img/cars/vesta-se/media/11.webp"),
//   require("@/assets/img/cars/vesta-se/media/12.webp"),
//   require("@/assets/img/cars/vesta-se/media/14.webp"),
//   require("@/assets/img/cars/vesta-se/media/15.webp"),
//   require("@/assets/img/cars/vesta-se/media/16.webp"),
//   require("@/assets/img/cars/vesta-se/media/17.webp"),
//   require("@/assets/img/cars/vesta-se/media/18.webp"),
//   require("@/assets/img/cars/vesta-se/media/19.webp"),
//   require("@/assets/img/cars/vesta-se/media/20.webp"),
//   require("@/assets/img/cars/vesta-se/media/21.webp"),
//   require("@/assets/img/cars/vesta-se/media/22.webp"),
//   require("@/assets/img/cars/vesta-se/media/23.webp"),
//   require("@/assets/img/cars/vesta-se/media/24.webp"),
//   require("@/assets/img/cars/vesta-se/media/25.webp"),
//   require("@/assets/img/cars/vesta-se/media/26.webp"),
//   require("@/assets/img/cars/vesta-se/media/27.webp"),
//   require("@/assets/img/cars/vesta-se/media/28.webp"),
//   require("@/assets/img/cars/vesta-se/media/29.webp"),
//   require("@/assets/img/cars/vesta-se/media/30.webp"),
//   require("@/assets/img/cars/vesta-se/media/31.webp"),
//   require("@/assets/img/cars/vesta-se/media/32.webp"),
// ];

/**
 * Медифайлы для пинов vesta sw
 */
const mediaXCross7 = [
  require("@/assets/img/cars/x-cross-7/media/16.webp"),
  require("@/assets/img/cars/x-cross-7/media/1.webp"),
  require("@/assets/img/cars/x-cross-7/media/2.webp"),
  require("@/assets/img/cars/x-cross-7/media/3.webp"),
  require("@/assets/img/cars/x-cross-7/media/4.webp"),
  require("@/assets/img/cars/x-cross-7/media/5.webp"),
  require("@/assets/img/cars/x-cross-7/media/6.webp"),
  require("@/assets/img/cars/x-cross-7/media/8.webp"),
  require("@/assets/img/cars/x-cross-7/media/9.webp"),
  require("@/assets/img/cars/x-cross-7/media/10.webp"),
  require("@/assets/img/cars/x-cross-7/media/11.webp"),
  require("@/assets/img/cars/x-cross-7/media/12.webp"),
  require("@/assets/img/cars/x-cross-7/media/13.webp"),
  require("@/assets/img/cars/x-cross-7/media/14.webp"),
  require("@/assets/img/cars/x-cross-7/media/15.webp"),
  require("@/assets/img/cars/x-cross-7/media/17.webp"),
  require("@/assets/img/cars/x-cross-7/media/18.webp"),
  require("@/assets/img/cars/x-cross-7/media/19.webp"),
  require("@/assets/img/cars/x-cross-7/media/20.webp"),
  require("@/assets/img/cars/x-cross-7/media/21.webp"),
  require("@/assets/img/cars/x-cross-7/media/22.webp"),
  require("@/assets/img/cars/x-cross-7/media/23.webp"),
  require("@/assets/img/cars/x-cross-7/media/24.webp"),
  require("@/assets/img/cars/x-cross-7/media/25.webp"),
  require("@/assets/img/cars/x-cross-7/media/27.webp"),
  require("@/assets/img/cars/x-cross-7/media/28.webp"),
  require("@/assets/img/cars/x-cross-7/media/29.webp"),
  require("@/assets/img/cars/x-cross-7/media/30.webp"),
  require("@/assets/img/cars/x-cross-7/media/31.webp"),
  require("@/assets/img/cars/x-cross-7/media/32.webp"),
  require("@/assets/img/cars/x-cross-7/media/33.webp"),
];

const ENJOY = [
  require("@/assets/img/enjoy/2-white.webp"),
  require("@/assets/img/enjoy/2.webp"),
  require("@/assets/img/enjoy/3-white.webp"),
  require("@/assets/img/enjoy/3.webp"),
  require("@/assets/img/enjoy/4.webp"),
  require("@/assets/img/enjoy/alice.webp"),
  require("@/assets/img/enjoy/alice-1.webp"),
  require("@/assets/img/enjoy/alice-2.webp"),
  require("@/assets/img/enjoy/alice-3.webp"),
  require("@/assets/img/enjoy/btn-left.webp"),
  require("@/assets/img/enjoy/btn-left-white.webp"),
  require("@/assets/img/enjoy/btn-left-white-revert.webp"),
  require("@/assets/img/enjoy/btn-left-revert.webp"),
  require("@/assets/img/enjoy/btn-bottom.webp"),
  require("@/assets/img/enjoy/btn-bottom-revert.webp"),
  // require("@/assets/img/enjoy/alice-bg.webp"),
  require("@/assets/img/enjoy/center.webp"),
  require("@/assets/img/enjoy/contacts.webp"),
  require("@/assets/img/enjoy/dark-left.webp"),
  require("@/assets/img/enjoy/dark-bottom.webp"),
  require("@/assets/img/enjoy/driver.webp"),
  require("@/assets/img/enjoy/first.webp"),
  require("@/assets/img/enjoy/frame.webp"),
  require("@/assets/img/enjoy/last.webp"),
  require("@/assets/img/enjoy/alice.webp"),
  require("@/assets/img/enjoy/location-dark.webp"),
  require("@/assets/img/enjoy/location-light.webp"),
  require("@/assets/img/enjoy/menu.webp"),
  require("@/assets/img/enjoy/music-2.webp"),
  require("@/assets/img/enjoy/music.webp"),
  require("@/assets/img/enjoy/nav-settings.webp"),
  require("@/assets/img/enjoy/nav-location.webp"),
  require("@/assets/img/enjoy/parking-1.webp"),
  require("@/assets/img/enjoy/parking-2.webp"),
  require("@/assets/img/enjoy/phone.webp"),
  require("@/assets/img/enjoy/settings-auto-menu.webp"),
  require("@/assets/img/enjoy/settings-sound-menu.webp"),
  require("@/assets/img/enjoy/settings.webp"),
  require("@/assets/img/enjoy/swipe-left-white.webp"),
  require("@/assets/img/enjoy/swipe-left.webp"),
  require("@/assets/img/enjoy/swipe-down.webp"),
  require("@/assets/img/enjoy/user.webp"),
  require("@/assets/img/enjoy/vesta-cross.mp4"),
  require("@/assets/img/enjoy/vesta.mp4"),
  require("@/assets/img/enjoy/LADA_audio.mp3"),
];

/**
 * Предзагружаемые ресурсы для ПК версии
 */
export const FILES = [
  // ...mediaVestaSE,
  ...mediaXCross7,
  ...ENJOY,
  // холл
  ...hallPanoramaList.map((e) => e.src),
  require("@/assets/img/icons/marker-eye.svg"),
  // туториал(модалка)
  ...TUTORIAL_LIST.map((e) => e.src),

  // кинотеатр
  cinema.background.light.src,

  // интерьеры
  require("@/assets/img/icons/marker-plus.svg"),
  // require("@/assets/img/cars/vesta-se/interior/dark-q75.webp"),
  // require("@/assets/img/cars/vesta-se/interior/light-q75.webp"),
  require("@/assets/img/cars/x-cross-7/interior/dark-q75.webp"),
  require("@/assets/img/cars/x-cross-7/interior/light-q75.webp"),

  // ...getAllGalleryImages(galleryVestaSE, "desktop"),
  ...getAllGalleryImages(galleryXCross7, "desktop"),
];

/**
 * Предзагружаемые ресурсы для мобильной версии
 */
export const FILES_MOBILE = [
  // ...mediaVestaSE,
  ...mediaXCross7,
  ...ENJOY,
  // холл
  ...hallPanoramaList.map((e) => e.srcMobile),
  require("@/assets/img/icons/marker-eye.svg"),
  // туториал(модалка)
  ...TUTORIAL_LIST.map((e) => e.srcMobile),

  // кинотеатр
  cinema.background.light.srcMobile,

  // интерьеры
  // require("@/assets/img/cars/vesta-se/interior/mobile/dark-q25.webp"),
  // require("@/assets/img/cars/vesta-se/interior/mobile/light-q25.webp"),
  require("@/assets/img/cars/x-cross-7/interior/mobile/dark-q25.webp"),
  require("@/assets/img/cars/x-cross-7/interior/mobile/light-q25.webp"),

  // ...getAllGalleryImages(galleryVestaSE, "mobile"),
  ...getAllGalleryImages(galleryXCross7, "mobile"),
];
