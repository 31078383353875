import { setDOMTheme } from "@/utils/helpers";

export const setTheme = (state, payload) => {
  state.theme = payload;
  setDOMTheme(payload);
};

export const setLoading = (state, payload) => {
  state.loading = payload;
};

export const hideTutorialModal = (state) => {
  state.tutorialModalShow = false;
};

export const showTutorialModal = (state) => {
  state.tutorialModalShow = true;
};

export const hideFeedbackModal = (state) => {
  state.feedbackModalShow = false;
};

export const showFeedbackModal = (state) => {
  state.feedbackModalShow = true;
};

export const hideWelcomeModal = (state) => {
  state.welcomeModalShow = false;
};

export const showWelcomeModal = (state) => {
  state.welcomeModalShow = true;
};

export const hideVideoModal = (state) => {
  state.videoModalShow = false;
};

export const showVideoModal = (state) => {
  state.videoModalShow = true;
};

export const setVideo = (state, payload) => {
  state.videoModalMedia = JSON.parse(payload);
};
export const hideEnjoyModal = (state) => {
  state.enjoyModalShow = false;
};

export const showEnjoyModal = (state) => {
  state.enjoyModalShow = true;
};
export const setEnjoyModel = (state, payload) => {
  state.enjoyModalModel = payload;
};

export const hidePopoverModal = (state) => {
  state.popoverModalShow = false;
};

export const showPopoverModal = (state) => {
  state.popoverModalShow = true;
};

export const setPopoverContent = (state, payload) => {
  state.popoverModalContent = payload;
};

export const setScreenOrientation = (state, payload) => {
  state.isPortrait = payload;
};

export const setWhiteThemeEnjoy = (state, payload) => {
  state.whiteThemeEnjoy = payload;
};

export const refreshHighlight = (state) => {
  state.highlight = false;
  setTimeout(() => {
    state.highlight = true;
  }, 200);
};
