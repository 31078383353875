import { RECAPTCHA_SITE_KEY } from "@/data/constants";
import { ref, watch } from "vue";
const widgetId = ref(null);

export const renderCaptcha = async (container) => {
  return new Promise((resolve) => {
    if (!window.smartCaptcha) {
      setTimeout(() => {
        renderCaptcha(container);
      }, 1000);
      return;
    }

    const id = window.smartCaptcha.render(document.getElementById(container), {
      sitekey: RECAPTCHA_SITE_KEY,
      invisible: true,
      hideShield: true,
      callback: (token) => {
        resolve(token);
      },
    });

    widgetId.value = id;
  });
};

// проверяем проинициализировался ли widget
// если нет, ждем инициализацию
const readyWidgetId = () => {
  if (widgetId.value !== null) {
    return;
  }

  return new Promise((resolve) => {
    watch(widgetId, () => resolve());
  });
};

export const subscribeCaptcha = async () => {
  await readyWidgetId();

  return new Promise((resolve, reject) => {
    if (!window.smartCaptcha) {
      reject();
      return;
    }

    const unsubscribe = () => {
      unsubscribeSuccess();
      unsubscribeHidden();
      unsubscribeNetwork();
    };

    const unsubscribeSuccess = window.smartCaptcha.subscribe(
      widgetId.value,
      "success",
      (token) => {
        if (typeof token === "string") {
          resolve(token);
        }
        unsubscribe();
        resetCaptcha();
      }
    );

    const unsubscribeHidden = window.smartCaptcha.subscribe(
      widgetId.value,
      "challenge-hidden",
      () => {
        reject();
        unsubscribe();
      }
    );

    const unsubscribeNetwork = window.smartCaptcha.subscribe(
      widgetId.value,
      "network-error",
      () => {
        reject();
        unsubscribe();
      }
    );

    executeCaptcha();
  });
};

export const executeCaptcha = () => {
  if (!window.smartCaptcha) {
    return;
  }

  window.smartCaptcha.execute();
};

export const resetCaptcha = () => {
  if (!window.smartCaptcha) {
    return;
  }

  window.smartCaptcha.reset();
};
