import {
  // PAGE_NAME_CINEMA,
  // PAGE_NAME_VESTA_SE,
  PAGE_NAME_VESTA_SW,
} from "@/data/constants";

export const PANORAMA_LIST = [
  {
    src: require("@/assets/img/hall/light-q85.webp"),
    srcMobile: require("@/assets/img/hall/mobile/light-q25.webp"),
    startLookAt: 245,
  },
];

export const INFOSPOT_LIST = [
  // Закомменчено до добавления в шоурум нового авто

  // {
  //   // vesta sedan
  //   position: {
  //     x: 4250,
  //     y: -900,
  //     z: 2800,
  //   },
  //   size: 320,
  //   sizeMobile: 450,
  //   className: "popover_id_main_left",
  //   nextPage: PAGE_NAME_VESTA_SE,
  //   areaHover: {
  //     position: {
  //       x: -3300,
  //       y: -300,
  //       z: 3100,
  //     },
  //     size: {
  //       width: 2700,
  //       height: 1300,
  //     },
  //     rotate: {
  //       y: 140,
  //     },
  //   },
  // },
  // {
  //   // кинотеатр
  //   position: {
  //     x: 5000,
  //     y: -100,
  //     z: 0,
  //   },
  //   size: 360,
  //   sizeMobile: 450,
  //   className: "popover_id_main_center",
  //   nextPage: PAGE_NAME_CINEMA,
  // },
  {
    // vesta sw cross
    position: {
      x: 7250,
      y: -900,
      z: -800,
    },
    size: 320,
    sizeMobile: 450,
    className: "popover_id_main_right",
    nextPage: PAGE_NAME_VESTA_SW,
    areaHover: {
      position: {
        x: -3300,
        y: -300,
        z: -3100,
      },
      size: {
        width: 2700,
        height: 1300,
      },
      rotate: {
        y: 50,
      },
    },
  },
];
