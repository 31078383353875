export const BASE_API = process.env.VUE_APP_BASE_API ?? "";
export const RECAPTCHA_SITE_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY ?? "";

export const THEMES = ["light", "dark"];

export const GALLERY_STEP_DEGREE = 10; // исходники содержут 360 кадров, в переменной указываем кол-во градусов поворота
export const GALLERY_TRANSITION = 500; // длительность анимации скрытие/показ пинов
export const GALLERY_TICK = 25; // кол-во мс при прокуртки
export const GALLERY_ROOT_FOLDER = "/static/cars/"; // корневая папка с исходниками
export const GALLERY_BEGIN = 0; // первый кадр
export const GALLERY_END = 360; // всего кадров

export const PAGE_NAME_CINEMA = "cinema";
export const PAGE_NAME_VESTA_SE = "vesta-se";
export const PAGE_NAME_VESTA_SW = "x-cross-7";

/**
 * Список доступных форм
 * /api/cms/private/feedbacks
 * Нам нужна форма покупка, id:1
 */
export const FEEDBACK_ID = 1;

// список моделей машин в форме обратной связи
export const FEEDBACK_MODEL_LIST = [
  {
    name: "X-Cross 7",
  },
];
